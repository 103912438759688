.ModalForm{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.31);
}

.Form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    background-color: rgba(0, 0, 0, 0.51);
    padding: 20px;
    border-radius: 5px;

}