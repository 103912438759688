.saveButton, .saveResetButton, .saveTrueButton, .saveErrorButton, .saveDelButton{
    border-radius: 100%;
    border: 1px solid black;
    width: 47px;
    height: 47px;
    margin-left: 20px;
    cursor: pointer;
}

.saveResetButton{
    border: 1px solid darkorange;
}
.saveTrueButton{
    border: 1px solid green;
}
.saveErrorButton, .saveDelButton{
    border: 1px solid red;
}

.ChangeReviewSliderItem{
    /*height: 300px;*/
    width: 250px;
}

.inputs{
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-between;

}

.inputs input[type=text]{
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px 15px;
    font-size: 14pt;
    font-family: "Hauss Regular";
    resize: none;

    transition: 0.3s;
}

.inputs textarea{
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px 15px;
    font-size: 14pt;
    font-family: "Hauss Regular";
    resize: none;
    margin-top: 10px;
    min-height: 65px;
    height: 65px;
    transition: 0.3s;
    /*z-index: 1;*/
}

.inputs textarea:focus{
    width: 218px;
    height: 300px;
    transition: height 0.3s;
}


.inputs input[type=file]{
    display: none;

}

.inputs input{
    margin-top: 10px;
    transition: 0.3s;
}

.fileLabel{

    align-self: center;

    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 1px black solid;
    cursor: pointer;
}

.fileLabel img{
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.buttons{
    margin-top: 10px;
    margin-left: -20px;
    display: flex;
    justify-content: center;
}