.Page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.container {
    width: 100%;
    height: calc(100% - var(--hederClone));

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.phone {
    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(75deg, #b29457 40%, #b29457 60%);

    font-family: "Hauss Black";
    font-size: 38pt;
}

.params {
    display: flex;
    justify-content: space-between;
    width: 100%;

    text-align: start;

    margin-top: 60px;

}

.title {
    font-family: "Hauss Bold";
    font-size: 15pt;
    color: #939393;
}

.body {
    font-family: "Hauss Bold";
    font-size: 18pt;
}

.inputForm, .textareaInput {
    margin-top: 24px;
    background: white;
    font-family: 'Hauss Regular';
    font-size: 14pt;
    padding: 12px 30px;
    border-radius: 42px;
    border: 1px solid var(--Gray-3, #E5E5E5);
    background: rgba(255, 255, 255, 0.00);
    height: 21px;


}

.textareaInput:focus, .inputForm:focus {
    box-shadow: inset 1.5px 1.5px 0px rgba(0, 0, 0, 0.48);
}
/* .textareaInput:focus, .inputForm:focus{
    display: block;
} */
.textareaInput:focus-visible, .inputForm:focus-visible{
    outline: none;
}

.form {
    margin-top: 60px;
    /*width: 84.2%;*/
    /*height: 100px;*/
    /*background: #a1a1a1;*/

    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.button {
    background: linear-gradient(90deg, #b29457 0%, #faf6c7 50%, #b29457 100%);
    border-radius: 4px;
    font-family: "Hauss Regular";
    font-size: 12pt;
    font-weight: bold;
    padding: 10px 36px;
    color: #565555;
    border-radius: 42px;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
    margin-top: 60px;
    /* width: 100%; */
    width: 300px;
    cursor: pointer;
}

.content {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid; */
    /* border-radius: 10px; */
    /* padding: 60px 30px; */
}

.conditions {
    display: flex;
    flex-direction: column;
    max-width: 1170px;
    justify-self: start;

    zoom: 80%;

    margin-top: 60px;
}

.conditionsTitle {
    font-family: "Hauss Black";

    font-size: 18pt;
    font-weight: bold;
    text-transform: uppercase;
    text-align: start;
}

.conditionsTable {
    font-family: "Hauss Regular";
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    margin-top: 10px;
    margin-left: -3px;
    height: 155px;
}

.imgsD {
    display: block;
}

.conditionTextD {
    display: block;
}

.imgs img {
    height: auto;
    /*height: 37px;*/
    /*margin-right: 3px;*/
    margin-left: 3px;
}

.imgsD img {
    height: 39px;
    margin-left: 3px;
}

.imgs, .imgsD {
    /*height: auto;*/
    display: flex;
    align-items: center;
    /*margin-right: 10px;*/
}

.conditionTextD, .conditionText {
    font-size: 16pt;
    display: flex;
    align-items: center;
    /*margin-right: 15px;*/
    margin-left: 15px;
    color: black;
}

.conditionsblock{
    display: flex;
    margin-left: 20px;
    margin-top: 4px;
}

.conditionsTable{
    display: flex;
    justify-content: flex-start;
}

.responseForm{
    font-family: "Hauss Regular";
    font-size: 18pt;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
}

.content{
    height: 100%;
}

.inputError{
    color: red;
    font-family: "Hauss Regular";
    font-size: 8pt;
    margin-top: 2px;
    width: 94%;
    height: 8px;
}

@media (min-width: 820px) {
    .responseForm{
        font-family: "Hauss Regular";
        font-size: 24pt;
        height: auto;
    }
    .content{
        height: auto;
    }

    .Page {
        zoom: 100%;
    }

    .form {
        width: 100%;
    }

    .inputForm, .textareaInput {
        font-family: 'Hauss Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 80%;

    }

    .button {
        font-size: 16pt;
    }

    .title {
        font-family: "Hauss Bold";
        font-size: 12pt;
        color: #939393;
    }

    .body {
        font-family: "Hauss Bold";
        font-size: 15pt;
    }

    .conditions {
        display: flex;
        flex-direction: column;
        max-width: 1170px;
        justify-self: start;

        zoom: 80%;
    }

    .conditionsTitle {
        font-family: "Hauss Black";

        font-size: 26pt;
        font-weight: bold;
        text-transform: uppercase;
        text-align: start;
    }

    .conditionsTable {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;


        margin-top: 10px;
        margin-left: -3px;
        height: 155px;
    }

    .imgsD {
        display: block;
    }

    .conditionTextD {
        display: block;
    }

    .imgs img {
        height: auto;
        margin-right: 3px;
    }

    .imgsD img {
        height: 39px;
        margin-right: 3px;
    }

    .imgs, .imgsD {
        display: flex;
        align-items: center;
    }

    .conditionTextD, .conditionText {
        font-size: 16pt;
        display: flex;
        align-items: center;
        margin-right: 15px;
        color: black;
    }

    .brD {
        display: block;
        width: 50px;
    }

}

@media (min-width: 1000px) {

    .title {
        font-family: "Hauss Bold";
        font-size: 15pt;
        color: #939393;
    }

    .body {
        font-family: "Hauss Bold";
        font-size: 18pt;
    }


}


@media (min-width: 1450px) {
    .Page {
        zoom: 100%;
    }

}


@media (max-width: 820px) {
    .content {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
    }

    .form {
        width: 100%;
    }

    .button {
        font-size: 14pt;
        margin-top: 30px;
        padding: 12px 36px;
    }

    .params {
        flex-wrap: wrap;
        justify-content: start;
        margin-top: 50px;
    }

    .info {
        margin-top: 10px;
        margin-left: 20px;
    }

    .title {
        font-family: "Hauss Bold";
        font-size: 10pt;
        color: #939393;
    }


    .body {
        font-family: "Hauss Bold";
        font-size: 13pt;
    }

    .phone {
        font-size: 30pt;
    }

    .conditionText{
        font-size: 12pt;
        width: 210px;
    }
    .form{
        margin-top: 10px;
    }

    .imgs{
        height: 19px;
        width: 90px;
        justify-content: end;
        align-items: center;
    }
    .conditionsblock{
        align-items: center;
    }
    .conditionsTable{
        justify-content: center;
    }

    .imgs img{
        height: 100%;
    }

    .conditions{
        margin-top: 20px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .content{
        /*padding-bottom: 0px;*/
        padding: 0;
        justify-content: space-around;

    }

    .conditionsTitle{
        font-size: 14pt;
        text-align: center;
    }
    .container{
        justify-content: space-between;
    }
}