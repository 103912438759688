.Page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    /* margin-bottom: 100px;
    margin-top: 100px; */
}

.title {
    font-size: 20pt;
    font-family: "Hauss Black";

    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(0deg, #b29457 20%, #faf6c7 50%, #b29457 80%);

    text-align: center;
    white-space: pre-wrap;
}

.slider {
    display: grid;
    grid-template-columns: auto 265px auto;
    grid-template-rows: 150px 270px;
    grid-row-gap: 20px;
    position: relative;
}

.leftButton {
    cursor: pointer;
    grid-row-start: 2;
}

.rightButton {
    cursor: pointer;
    grid-column-start: 3;
    grid-row-start: 2;
}

.feedbacks {
    grid-column-start: 2;
    grid-row: 1/3;
    overflow: hidden;
    width: 265px;
    height: 100%;
    display: flex;
}

.feedback {
    height: 100%;
    min-width: 210px;
    margin: 0 27.5px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 150px 265px;
    grid-row-gap: 20px;

    transition: transform 0.5s;
}

.feedbackImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.userInfo {
    white-space: pre-wrap;
    text-align: center;

    font-family: "Alumma Regular";
    font-weight: bold;
    font-size: 12pt;
}

.imgBorder1 {
    border-radius: 100%;
    /*border: 5px  solid;*/
    background-image: linear-gradient(0deg, #b29457 0%, #faf6c7 50%, #b29457 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgBorder1 img {
    border-radius: 100%;
    margin: 3px;
    width: 100px;
    height: 100px;

    border: 3px white solid;
}

.feedback {

}

.massage {
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.5);
    font-style: italic;
}

.massage p {
    margin: 21px;
    text-align: start;
    line-height: 14pt;

    font-family: "Alumma Regular";
    font-weight: bold;
    font-size: 10pt;
}

.link {
    cursor: pointer;
    white-space: pre-wrap;
    width: 320px;
    text-align: center;
    color: #3587d4;
    text-decoration-line: underline;
}

.form {
    position: absolute;
    z-index: 2;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: #a1a1a1;

    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*justify-content: space-between;*/
    /*margin: 20px;*/
    /*padding: 20px;*/
}

.formContainer {
    width: calc(100% - 40px);
    height: calc(100% - 40px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin: 20px;
    /*position: relative;*/
}

.textInput, .textareaInput {
    /*margin-top: 20px;*/
    width: calc(100% - 40px);
    background: white;
    box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.48);

    font-family: "Alumma Regular";
    font-size: 12pt;
    font-weight: bold;
    padding: 11px 11px;
    outline: none;
    border: rgb(118, 118, 118) 1px solid;
}

.textareaInput {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    height: 205px;
    resize: none;
    /*min-width: 100%;*/

}

.textareaInput:focus, .textInput:focus {
    box-shadow: inset 1.5px 1.5px 0px rgba(0, 0, 0, 0.48);
}

.submit {
    cursor: pointer;
    background: linear-gradient(90deg, #b29457 0%, #faf6c7 50%, #b29457 100%);
    border-radius: 4px;
    font-family: "Hauss Regular";
    font-size: 12pt;
    font-weight: bold;
    padding: 10px 36px;
    color: #565555;

    border-radius: 10px;

    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
}

.error {
    font-family: "Hauss Regular";
    font-size: 8pt;
    color: red;

    margin-bottom: 5px;
    margin-top: 2px;

    text-align: start;
    width: 95%;
}


.formResponse {
    font-family: "Hauss Regular";
    font-size: 16pt;
    color: white;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
}

@media (min-width: 820px) {
    .Page {
        zoom: 70%;
        width: 100%;

    }

    .content {
        width: 100%;
    }

    .slider {
        /*width: calc(calc(265px * 3) + 38px);*/
        zoom: 130%;

        grid-template-columns: 19px calc(265px * 3) 19px;
        grid-template-rows: 150px 240px;
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(0.7);
            height: 140%;
            margin-top: -130px;
        }

        .slider {
            transform: scale(1.3);
        }
    }


    .feedbacks {
        width: calc(265px * 3);
    }

    .title {
        font-size: 34pt;
        white-space: normal;
        background-image: linear-gradient(75deg, #b29457 45%, #faf6c7 50%, #b29457 55%);
    }

    .massage {
        height: 230px;
    }

    .link {
        white-space: normal;
        width: 100%;
        font-size: 16pt;
        font-family: "Alumma Regular";

    }
}

@media (min-width: 1450px) {

    .Page {
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(1);
            height: 100%;
            margin-top: 0px;
        }
    }

}