.App{
    /*display: flex;*/
    overflow: auto;

    flex-flow: column nowrap;
    height: 100vh;
    /*height: calc(var(--vh, 1vh) * 100);*/
    scroll-behavior: smooth;

}
    
.item, .itemMobile{
    /*scroll-snap-align: start;*/
    height: 100vh;
    min-height: 665px;
    max-height: 665px;
    overflow: hidden;
}
@-moz-document url-prefix() {
    .item, .itemMobile {
        /*scroll-snap-align: start;*/
        height: 100vh;
        min-height: 780px;
        max-height: 820px;
        overflow: hidden;
    }
}


@media (min-width: 820px) {
    .item{
        min-height: 680px;
        max-height: 680px;
    }

    .itemMobile{
        display: none;
    }



    @-moz-document url-prefix() {

        .item{
            scroll-snap-align: none;
            min-height: 625px;
            max-height: 625px;
        }

    }

}

@media (min-width: 1450px) {
    .item{
        scroll-snap-align: start;

        height: 100vh;
        min-height: auto;
        max-height: none;
    }

    @media (max-height: 920px) {
        .item{
            scroll-snap-align: none;
            min-height: 1080px;
            max-height: 1080px;
        }
    }
}