.HeaderTop {
    height: 160px;
    width: 100%;
    /*background-color: rgba(196, 196, 196, 0.84);*/
    /* background-color: #a1a1a1; */
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(22.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    /*zoom: 70%;*/
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    transition: background-color 0.3s;
}

.Header {
    height: 160px;
    width: 100%;
    /*background-color: rgba(196, 196, 196, 0.84);*/
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(22.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    /*zoom: 70%;*/
    /*zoom: 100%;*/
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    transition: background-color 0.3s;
}

.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    /*min-width: 810px;*/

}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    /*min-width: 740px;*/
    min-width: 1170px;
    max-width: 1170px;
}

.info {
    min-width: 270px;
    min-height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.phone, .infoText {
    font-family: "Alumma Regular";
    font-size: 24pt;
    font-weight: 400;
    color: white;
    /*font-size: 21px;*/
}

.infoText {
    font-size: 18pt;
}

.infoForm{
    display: none;
}

.infoFormActive{
    top: 117px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    background-color: rgba(0, 0, 0, 0.51);
    padding: 20px;
    border-radius: 5px;
}

.inputForm, .textareaInput {
    /*margin-top: 20px;*/
    width: calc(100% - 40px);
    background: white;
    box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.48);

    font-family: "Alumma Regular";
    font-size: 12pt;
    font-weight: bold;
    padding: 11px 11px;
    outline: none;
    border: rgb(118, 118, 118) 1px solid;


}

.textareaInput:focus, .inputForm:focus {
    box-shadow: inset 1.5px 1.5px 0px rgba(0, 0, 0, 0.48);
}

.button{
    cursor: pointer;
    background: linear-gradient(90deg, #b29457 0%, #faf6c7 50%, #b29457 100%);
    border-radius: 4px;
    font-family: "Hauss Regular";
    font-size: 12pt;
    font-weight: bold;
    padding: 10px 36px;
    color: #565555;

    border-radius: 10px;

    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
}

.inputError{
    font-family: "Hauss Regular";
    font-size: 16pt;
    color: #ffffff;
    width: 90%;
}

.resultForm{
    font-family: "Hauss Regular";
    font-size: 16pt;
    color: #ffffff;
    width: 90%;
}
.close{
    color: white;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 16pt;
    margin-top: -20px;
    margin-right: -20px;
}

.close div{
    cursor: pointer;
    text-align: center;
}


@media (min-width: 820px) {
    .inputForm, .textareaInput {
        font-family: "Alumma Regular";
        font-size: 20pt;
    }
    .button{
        font-size: 20pt;
    }
}

@media (max-width: 1450px) {

    .Header, .HeaderTop {
        zoom: 70%;
        -moz-transform: scale(1,0.7);
        -moz-transform-origin:0 0;
    }

    @-moz-document url-prefix() {
        .container{
            -moz-transform: scale(0.7,1);
            min-width: 1000px;
        }
    }

}

@media (max-width: 1180px) {

    .Header, .HeaderTop {
        height: 160px;
    }

    .container {
        width: 90%;
        min-width: auto;
        display: flex;
        justify-content: space-between;

    }

    .content {
        width: 100%;
        min-width: auto;
        max-width: 1100px;
    }

    @-moz-document url-prefix() {
        .container{
            width: 100%;
            -moz-transform: scale(0.7,1);
            min-width: 1100px;
        }

        .content {
            width: 100%;
            /*-moz-transform: scale(1,1);*/
            min-width: 1050px;
            max-width: 1100px;
        }
    }
}

@media (max-width: 820px) {
    .Header, .HeaderTop {
        height: 115px;
        -moz-transform: scale(1,0.7);
    }

    .container {
        width: 100%;
        min-width: 450px;
        display: flex;
        justify-content: center;

    }

    .content {
        width: 100%;
        min-width: auto;
        max-width: 500px;
    }

}

@media (min-width: 506px) {
    .Header {
        /*zoom: 100%;*/
    }

}

/*                Стили логотипа                     */
.logo {
    display: flex;
    align-items: center;

}

.img, .imgDesktop {
    height: 100px;
}

.imgDesktop img{
    /*width: 100%;*/
    height: 100%;
}

.img img {
    /*width: 100%;*/
    height: 100%;
}

.img{
    display: none;
}

.logoText {
    margin-left: 20px;
}

.logoText {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.textTop {
    font-family: 'ALSAlumma';
    color: white;
    font-size: 45px;
    letter-spacing: 0.05em;
}

.textBottom {
    margin-top: 5px;
    font-family: 'Hauss Thin';
    color: white;
    font-size: 12px;
    text-indent: 3px;
    letter-spacing: 0.05em;
}

@media (max-width: 820px) {
    .img {
        height: 78px;
        display: block;
    }

    .imgDesktop{
        display: none;
    }

    .logo {
        margin-right: 18px;
    }

    .logoText {
        display: none;
    }
}

/*        Стили кнопки "не плати за связь"        */

.buttonBlock {


    display: flex;
    height: 90px;
    align-items: flex-end;
    justify-content: center;
}

.noManyButton, .noManyButtonMobile {
    border: 2px solid white;
    border-radius: 10px;
    padding: 0px 13px 6px 13px;
    background-color: rgba(255, 255, 255, 0);

    font-family: 'Hauss Black';
    color: white;
    /*font-size: 18px;*/
    font-size: 24pt;

    white-space: pre-wrap;
    line-height: 20pt;
    margin-bottom: 20px;

    height: 41px;
    cursor: pointer;
}

.noManyButtonMobile {
    display: none;
}

@media (max-width: 820px) {
    .noManyButtonMobile {
        font-family: 'Hauss Black';
        display: block;
        max-width: 205px;
        padding: 4px 29px;
        font-size: 14pt;
        height: auto;
        line-height: inherit;
        margin: 0;
    }

    .noManyButton {
        display: none;
    }

    .buttonBlock {
        align-items: center;
    }
}

/*       Стили телефона        */

.infoMobile {
    display: none;
    height: 45px;
}

@media (max-width: 820px) {
    .info {
        display: none;
    }

    .infoMobile {
        display: flex;
        height: 43px;
    }
}

/*       Стили кнопки меню        */

.menuMobile {
    cursor: pointer;
    height: 38px;
}

.menu {
    cursor: pointer;
    height: 41px;
    width: 50px;
    min-height: 41px;
    min-width: 50px;
}

.menuMobile {
    display: none;
}

.menu img {
    /*width: 100%;*/
    height: 100%;

    position: relative;
}

.menuMobile img {
    /*width: 100%;*/
    height: 100%;

    position: relative;
}


@media (max-width: 820px) {
    .menu {
        display: none;
    }

    .menuMobile {
        display: flex;
        /*margin-left: 10%;*/
    }

}


/*uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu*/

@media (min-width: 1181px) {
    .container{
        display: grid;
        grid-template-columns: 1fr 1170px 1fr;
        align-items: center;
        justify-items: center;
    }
}