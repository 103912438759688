.SevenPage {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.fonTop {
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 82%;
    background: radial-gradient(45.7% 45.7% at 50% 50%, rgba(0, 0, 0, 0.77) 0%, #000000 100%);
}

.fonBottom {
    top: 82%;
    left: 0;

    position: absolute;
    z-index: -1;
    background: radial-gradient(45.7% 45.7% at 50% 50%, rgba(0, 0, 0, 0.77) 0%, #000000 100%);
    width: 100%;
    height: 18%;
}

.content, .contentD {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin-bottom: 30px;
    /* margin-top: 140px; */
}

.contentD {
    display: none;
}

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
}

.title {
    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(75deg, #b29457 30%, #faf6c7 50%, #b29457 70%);

    font-family: "Hauss Black";
    font-size: 18pt;
    max-width: 260px;
    /*margin-top: 30px;*/

    text-align: center;

    text-transform: uppercase;

}

.text {
    font-family: "Alumma Regular";
    color: #FFFFFF;
    font-weight: bold;
    font-size: 12pt;
    max-width: 280px;
    text-align: center;
    line-height: 16pt;
    margin-top: 2px;
}

.text span {
    color: #2c66a0;
    cursor: pointer;
}

.link {
    cursor: pointer;
    color: #2c66a0;
    font-family: "Alumma Regular";
    font-weight: bold;
    font-size: 12pt;

    /*text-decoration-line: underline;*/
    border: 0px #2c66a0 solid;
    border-bottom-width: 1px;
    padding-bottom: 1px;

    position: relative;
}

.blockModal {
    position: relative;
}

.Modal {
    position: fixed;
    /*z-index: 1000;*/
    z-index: 900;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 0, 0, 0);
    touch-action: none;
}

.ModalContainer {
    cursor: default;
    position: absolute;
    /*z-index: 1001;*/
    z-index: 901;
    top: calc(-572px - 20px);
    left: -61px;
    width: 292px;
    height: 572px;
    background-color: rgba(129, 129, 129, 1);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    touch-action: none;

}

.ModalContainer::after {
    content: '';
    position: absolute; /* Абсолютное позиционирование */
    right: calc(calc(292px - 30px) / 2);
    /*bottom: -20px;*/
    bottom: -30px;

    /*background-color: inherit;*/
    border: 15px solid transparent;
    border-top: 15px solid rgba(129, 129, 129, 1);
}

.ModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    line-height: 14pt;
    height: 490px;
    touch-action: none;
}

.ModalText {
    font-family: "Alumma Regular";
    font-size: 12pt;
    font-weight: bold;
    color: white;

    max-width: 220px;
    text-align: center;
    touch-action: none;
}

.ModalText span {
    text-decoration-line: underline;
}

.qrCode{
    display: none;
}


.qrCode img{
    width: 100%;
    border-radius: 5px;
}

.text span:hover > .qrCode{
    display: none;
}



@media (min-width: 820px) {

    .SevenPage {
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .SevenPage{
            transform: scale(1);
        }

        .contentD{
            transform: scale(0.7,0.7);
        }
    }

    .qrCode{
        display: block;
        opacity: 0;
        position: absolute;
        transition: opacity 0.5s;
        border-radius: 5px;
        width: 200px;
        margin-left: 27.5px;
    }

    .text span:hover > .qrCode{
        display: flex;
        opacity: 1;
        transition: opacity 0.5s;
    }
    .content {
        display: none;
    }

    .contentD {

        display: flex;

        width: 1000px;
        max-width: none;
        white-space: pre-wrap;
    }

    .contentD span {
        text-decoration-line: underline;
    }

    .title {
        max-width: none;
        font-size: 34pt;
        text-transform: none;
    }

    .text {
        margin-top: 15px;
        max-width: none;
        font-size: 18pt;
        font-weight: normal;
        line-height: 22pt;
    }

    .link {
        font-size: 16pt
    }

    .ModalContainer {
        width: 970px;
        height: 450px;

        top: calc(-472px - 20px);
        left: -370px;
    }

    .ModalContainer::after {
        content: '';
        position: absolute; /* Абсолютное позиционирование */
        right: calc(calc(472px));
        /*bottom: -20px;*/
        bottom: -30px;

        /*background-color: inherit;*/
        border: 15px solid transparent;
        border-top: 15px solid rgba(129, 129, 129, 1);
    }

    .ModalContainer {
        /*width: 100%;*/
        width: 970px;
        height: 450px;
    }

    .ModalContent {
        width: 100%;
        padding: 20px 0;
    }

    .ModalText {
        max-width: none;
        width: 877px;
        font-size: 20pt;
        font-weight: normal;
        line-height: 24pt;
        /*height: 400px;*/

    }

}

@media (min-width: 1450px) {

    .SevenPage {
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .SevenPage{
            transform: scale(1);
        }

        .contentD{
            transform: scale(1);
        }
    }
}