.Page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    /*margin-bottom: 30px;*/
    margin-top: 30px;
}

.title, .titleActive {
    font-family: "Hauss Black";
    font-size: 20pt;
    text-align: center;
}

.otvets, .otvetsActive {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    transition: margin-top 0.3s;
    background: rgba(255, 255, 255, 0.51);
}

.question {
    cursor: pointer;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: 0px solid #929090;
    border-bottom-width: 1px;

}

.questionActive {

}

.questionText {
    font-family: "Alumma Regular";
    font-weight: bold;
    /*font-size: 14pt;*/
    white-space: pre-wrap;
}

.answer {
    height: 0;
    overflow: auto;

    transition: height 0.5s;
}

.answerActive {
    /*height: 170px;*/
    padding: 10px 5px;
    height: 300px;
    overflow: auto;

    transition: height 0.5s;
}

.answer, .answerActive {
    font-size: 12pt;
    white-space: pre-wrap;
    line-height: 20pt;
    /*text-indent: 30px;*/
    font-family: "Alumma Regular";
    font-weight: bold;
    color: #484848;
    /*background: rgba(255, 255, 255, 0.51);*/
}

.hand {
    opacity: 1;
    height: 218px;

    transition: 0.5s;
    /*transition: opacity 0.2s;*/
}

.handDisable {
    opacity: 0;
    height: 0px;

    transition: 0.5s;
    /*transition: opacity 0.2s;*/
}

.rukaD {
    display: none;
}

.questionIcon {
    opacity: 1;
    transition: 0.5s;
}

@media (min-width: 820px) {
    .Page {
        zoom: 70%
    }



    .content {
        justify-content: start;
    }

    .title {
        margin-top: 100px;
        font-size: 34pt;
        width: 55.7%;
        text-align: start;
        transition: margin-top 0.5s;
    }

    .titleActive {
        margin-top: 10px;
        font-size: 34pt;
        width: 55.7%;
        text-align: start;
        transition: margin-top 0.5s;
    }

    .otvets {
        margin-top: 100px;
        max-width: 55.7%;
        transition: margin-top 0.5s;
    }

    .otvetsActive {
        margin-top: 10px;
        max-width: 55.7%;
        transition: margin-top 0.5s;
    }

    .item {
        margin-top: 10px;
    }

    .questionText {
        font-size: 18pt;
    }

    .handDisable, .hand, .questionIcon {
        display: none;
    }

    .rukaD {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 658px;
        z-index: -1;
    }

    .answerActive {
        /*height: 170px;*/
        padding: 10px 5px;
        height: 350px;
        overflow: auto;

        transition: height 0.5s;
    }

    .answer, .answerActive {
        font-size: 16pt;
        white-space: pre-wrap;
        line-height: 20pt;
        /*text-indent: 30px;*/
        font-family: "Alumma Regular";
        font-weight: bold;
        color: #484848;
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(0.7);
            height: 25%;
            width: 119%;
        }
        .title{
            margin-top: 0;
            align-self: start;
        }

        .otvets{
            align-self: start;
        }

        .titleActive{
            align-self: start;
        }

        .otvetsActive{
            align-self: start;
        }

        .rukaD{
            margin-bottom: -700px;
        }
    }
}

@media (min-width: 1450px) {
    .Page {
        zoom: 100%
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(1);
        }
        .Page {
            height: 100%;
            width: 100%;
            align-items: center;
        }
        .title{
            margin-top: 90px;
            align-self: center;
        }

        .otvets{
            align-self: center;
        }

        .titleActive{
            align-self: center;
        }

        .otvetsActive{
            align-self: center;
        }

        .rukaD{
            margin-bottom: 0px;
        }
    }
}