*|*:not(:root):fullscreen {
    position:fixed !important;
    inset:0 !important;
    margin:0 !important;
    box-sizing:border-box !important;
    min-width:0 !important;
    max-width:none !important;
    min-height:0 !important;
    max-height:none !important;
    width:100% !important;
    height:100% !important;
    transform:none !important;

    /* intentionally not !important */
    object-fit:contain;
}
.ant-modal-wrap{
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(50px);
}
 .ant-modal-content.ant-modal-content .ant-modal-header{
    text-align: center;
    margin-top: 48px;
}
.ant-modal-content.ant-modal-content .ant-modal-header .ant-modal-title{
    color: var(--Gray-1, #1F1F1F);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-family: 'Hauss Regular';
}
.ant-modal-content.ant-modal-content .ant-modal-body {
    max-width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}
.ant-modal-content.ant-modal-content .ant-modal-body .form-request{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}
.ant-modal-content.ant-modal-content .ant-modal-body .subtitle-modal-form{
    margin-top: 12px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Hauss Regular';
}
.ant-modal-content.ant-modal-content .ant-modal-body .subtitle-modal-form .bold{
    font-weight: 700;
}
.ant-modal-content.ant-modal-content .ant-modal-body .form-request .error-img-name img{
    position: absolute;
    top: 145px;
    right: 135px;

}
.ant-modal-content.ant-modal-content .ant-modal-body .form-request .error-img-phone img{
    position: absolute;
    top: 213px;
    right: 135px;
}

input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

*{
    margin: 0;
    padding: 0;
    /*display: block;*/
}

:root{
    --vh:1;
    --hederClone:160px;
    --desktopFull:1400px;
}

.headerClone{
    height: 160px;
    /*zoom:70%;*/
    zoom: 100%;
}



@media (max-width: 1450px){
    .headerClone{
        height: 115px;
        /*zoom:70%; */
    }
    :root{
        --vh:1;
        --hederClone:0px;
    }
    @-moz-document url-prefix() {

       .headerClone{
           height: 0px;
       }
    }
}
@media (max-width: 820px){
    .headerClone{
        height: 40px;
        /*zoom:70%; */
    }

}
@media (max-width: 425px){
    .ant-modal-content.ant-modal-content .ant-modal-body .subtitle-modal-form{
        white-space: pre-wrap;
        text-align: start;
    }
    .bl-request-modal{
        top: 55px;
    }
    .ant-modal-content.ant-modal-content .ant-modal-body .form-request .error-img-name img{
        right: 65px;
    }
    .ant-modal-content.ant-modal-content .ant-modal-body .form-request .error-img-phone img{
        right: 65px;
    }
}
@media (max-width: 390px){
    .ant-modal-content.ant-modal-content .ant-modal-body .form-request .error-img-name img{
        right: 50px;
    }
    .ant-modal-content.ant-modal-content .ant-modal-body .form-request .error-img-phone img{
        right: 50px;
    }
}
@media (max-width: 375px){
    .ant-modal-content.ant-modal-content .ant-modal-body .form-request .error-img-name img{
        right: 45px;
    }
    .ant-modal-content.ant-modal-content .ant-modal-body .form-request .error-img-phone img{
        right: 45px;
    }
}
html {
    scroll-behavior: smooth;
}

a {
    color: inherit;
    text-decoration: inherit;
}

button, input{
    border: none;
    background: none;
}

@font-face {
    font-family: 'ALSAlumma';
    src: url("fonts/ALS_Alumna.otf");
}

@font-face {
    font-family: 'Alumma Regular';
    src: url("fonts/ALS Alumna Regular.ttf");
}

@font-face {
    font-family: 'Hauss Black';
    src: url("fonts/ALS_Hauss_Black_1.002.otf");
}
@font-face {
    font-family: 'Hauss Bold';
    src: url("fonts/ALS_Hauss_Bold_1.002.otf");
}

@font-face {
    font-family: 'Hauss Thin';
    src: url("fonts/ALS_Hauss_Thin_1.002.otf");
}

@font-face {
    font-family: 'Hauss Regular';
    src: url("fonts/ALS_Hauss_Regular_1.002.otf");
}

@font-face {
    font-family: 'Hauss Light';
    src: url("fonts/ALS_Hauss_Light_1.002.otf");
}

@font-face {
    font-family: 'Gothampro Bold';
    src: url("fonts/gothampro_bold.ttf");
}
@font-face {
    font-family: 'Hauss Book';
    src: url("fonts/ALS Hauss Book.ttf");
}
@font-face {
    font-family: 'Hauss Medium';
    src: url("fonts/ALS_Hauss_Medium_1.002.otf");
}
@font-face {
    font-family: 'Ubuntu Mono R';
    src: url("fonts/UbuntuMono-R.ttf");
}
@font-face {
    font-family: 'Ubuntu Mono B';
    src: url("fonts/UbuntuMono-B.ttf");
}
@font-face {
    font-family: 'Roboto Mono Bold';
    src: url("fonts/RobotoMono-Bold.ttf");
}
@font-face {
    font-family: 'Roboto Regular';
    src: url("fonts/ALS_Roboto/Roboto-Regular.ttf");
}


