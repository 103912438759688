.Admin{
    width: 94%;
    padding: 2% 3%;
}

.AdminHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AdminHeaderPaginate{
    display: flex;
    align-items: center;
    gap: 50px;
}

.AdminHeaderPaginateLeftButton, .AdminHeaderPaginateRigthButton{
    user-select: none;
    cursor: pointer;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminHeaderPaginatePonel{
    display: flex;
    align-items: center;
    gap:20px;
    height: 80px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 21px;
    padding: 9px 23px;
}

.AdminHeaderPaginatePonelButton, .AdminHeaderPaginatePonelButtonActive{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;


    user-select: none;
    cursor: pointer;

    color: #8D8D8D;
    font-size: 48px;
    font-family: "Hauss Regular";
}

.AdminHeaderPaginatePonelButtonActive{
    background: black;
    color: white;
}


.AdminHeaderHelp, .AdminHeaderExit{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;

    color: #8D8D8D;
    font-size: 48px;
    font-family: "Hauss Regular";

    user-select: none;
    cursor: pointer;
    position: relative;
}

.AdminHeaderExit{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.AdminHeaderExit:hover{
    background-color: black;
    transition: 0.3s;
}

.AdminHeaderExit svg{
    width: 48px;
    height: 48px;
    margin-left: 5px;
    margin-top: 5px;
    fill: #8D8D8D;
}

.AdminHeaderExit:hover svg{
    fill: white;
    transition: 0.3s;
}

.helpInfo{
    display: none;
    position: absolute;
    /*top: 0;*/
    /*left: 0;*/
    right: 100px;
    top: -10px;
}

.AdminHeaderHelp:hover .helpInfo{
   display: flex;
}

.main{
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.desktop, .desktopPlus{
    width: 1024px;
    height: 700px;
    max-height: 720px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    /*padding-bottom: 20px;*/
    padding-bottom: 20px;

    display: flex;
    justify-content: space-between;
    /*align-items: start;*/
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
}

.desktopPlus{
    width: 1500px !important;
}
.mobile{
    width: 425px;
    height: 720px;
    max-height: 720px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;

    overflow: auto;
}

.end{
    height: 500px;
    min-height: 500px;
    max-height: 500px;
    width: 100%;
}


.AdminAuth{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    font-family: "Hauss Regular";
}

.AuthForm{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 400px;
    border-radius: 10px;
    border: black 1px solid;

    padding: 30px 60px;
}

.title{
    font-size: 28pt;
    margin-bottom: 20px;
}

.adminFormInput{
    border: black 1px solid;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 14pt;
    width: 300px;
    margin-top: 10px;
}

.adminFormButton, .adminFormButtonDisable{
    margin-top: 30px;
    border: black 1px solid;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 14pt;
    width: 300px;
    cursor: pointer;
    transition: 0.3s;
}

.adminFormButton:hover{
    color: white;
    background-color: black;
    transition: 0.3s;
}

.errorText{
    margin-top: 10px;
    color: red;
}