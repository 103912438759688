.Rate{

}

.rate {


    display: grid;
    grid-template-columns: 130px 130px 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    min-height: 50px;
    padding: 10px 0;

    border: 0px solid black;
    border-top-width: 1px;
}

.plug {
    cursor: pointer;
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    color: #454444;
    height: 22px;
    background: linear-gradient(0deg, #b29457 10%, #faf6c7 40%, #b29457 100%);
    border-radius: 4px;
    font-family: "Hauss Regular";
    font-size: 6.53pt;
    font-weight: bold;
}

.rateText {
    grid-column-start: 3;
    grid-row-start: 3;
    align-self: center;

    font-family: "Alumma Regular";
    font-size: 10pt;
    font-weight: bold;
    color: #a1a1a1;
}


.phone, .phoneActive {
    cursor: pointer;
    font-size: 9.82pt;
    align-self: center;
    white-space: nowrap;
    font-family: 'Roboto Regular';
    font-weight: normal;
}

.phoneActive {
    /*border: 1px black solid;*/
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
}

@media (min-width: 820px) {

    .rate{
        grid-template-columns: 200px 200px 1fr;

        /*grid-row-gap: 5px;*/
        grid-column-gap: 5px;
    }

    .phone, .phoneActive {
        font-size: 17pt;
        color: #454444;
        align-self: center;
        font-family: 'Roboto Regular';
    font-weight: normal;
    }

    .plug {
        grid-column-start: 3;
        grid-row-start: 1;
        grid-row-end: 3;

        font-size: 16pt;
        /*font-weight: normal;*/

        height: 35px;

        border-radius: 10px;
        width: 150px;
        justify-self: end;

        color: #454444;
        background: linear-gradient(0deg, #b29457 00%, #faf6c7 50%, #b29457 100%);
    }

    .rateText {
        grid-column-start: 3;
        grid-row-start: 3;
        align-self: center;
        justify-self: end;

        font-size: 18pt;

        color: #454444;
    }

}