.SixPage {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.fonTop {
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 82%;
    background: radial-gradient(45.7% 45.7% at 50% 50%, rgba(0, 0, 0, 0.77) 0%, #000000 100%);
}

.fonBottom {
    top: 82%;
    left: 0;

    position: absolute;
    z-index: -1;
    background: radial-gradient(45.7% 45.7% at 50% 50%, rgba(0, 0, 0, 0.77) 0%, #000000 100%);
    width: 100%;
    height: 18%;
}

.title {
    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(75deg, #b29457 30%, #faf6c7 50%, #b29457 70%);

    font-family: "Hauss Black";
    font-size: 18pt;
    max-width: 300px;
    text-align: center;
    text-transform: uppercase;
    white-space: pre-wrap;

}

.text {
    font-family: "Alumma Regular";
    font-size: 20pt;
    white-space: pre-wrap;
    color: white;
    text-align: center;
}

.text2, .text2D {
    font-family: "Alumma Regular";
    font-size: 16pt;
    white-space: pre-wrap;
    text-align: center;
    font-weight: normal;

    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(75deg, #b29457 30%, #faf6c7 50%, #b29457 70%);

}

.text2D {
    display: none;
}

.video {
    max-width: 320px;
    max-height: 180px;
}

.video video {
    width: 100%;
    height: 100%;
}

@media (min-width: 820px) {

    .SixPage {
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .SixPage {
            transform: scale(1 , 1);
        }

        .content{
            transform: scale(0.7,0.7);
        }
    }

    .content {
        width: 1170px;
    }

    .title {
        max-width: none;
        font-size: 46pt;
        line-height: 46pt;
        /* margin-top: 160px; */
    }

    .text {
        max-width: none;

        font-family: "Hauss Medium";
        font-weight: bold;

        font-size: 42pt;

        white-space: normal;

        letter-spacing: 3pt;
    }

    .video {
        max-width: 590px;
        max-height: 395px;
    }

    .video video {
        width: 100%;
        /*height: 100%;*/
    }

    .text2 {
        display: none;
    }

    .text2D {
        display: block;
        /*white-space: normal;*/
        /*max-width: 1170px;*/
        font-weight: bold;
        letter-spacing: 1pt;
        line-height: 24pt;
    }
}

@media (min-width: 1450px) {

    .SixPage {
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .SixPage {
            transform: scale(1);
        }


        .content{
            transform: scale(1);
        }
    }
}