::-webkit-scrollbar {
    display: none;
}


.Phone {
    width: 100%;
    display: flex;
    justify-content: center;
    zoom: 113%;



}

@-moz-document url-prefix() {
    .Phone {
        transform: scale(0.95);
    }
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 22px;
}

.PhoneImg {
    width: 340px;
    height: 442px;
}

.PhoneImg img {
    width: 100%;
    height: 100%;
}

.PhoneContainer {
    position: absolute;
    top: 20px;
    left: 75px;
}

.PhoneContainerNumbers {
    position: relative;
}

.textPhone {
    font-family: "Roboto Mono Bold";
    font-size: 15pt;
    margin-top: 18px;
    cursor: pointer;
}

.textPhoneActive {
    font-family: "Roboto Mono Bold";
    font-size: 15pt;
    margin-top: 18px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.51);
    color: #662677;
    cursor: pointer;
}

.PhoneNumbers {
    max-height: 320px;
    overflow: auto;
    scroll-behavior: smooth;
}

.PhoneNumbers::-webkit-scrollbar {
    height: 0;
}

.endPhones {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-family: "Hauss Regular";
    white-space: pre-wrap;
    text-align: center;
}

.startPhones {
    height: 50px;
    width: 100%;
}

.whiteFogTop {

    position: absolute;
    top: 0;

    background: linear-gradient(180deg, #FFFFFF 10%, rgba(255, 255, 255, 0) 100%);
    height: 120px;
    width: 100%;

    pointer-events: none;
}

.whiteFogBottom {

    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #FFFFFF 100%);
    height: 120px;
    width: 100%;

    z-index: 99;

    pointer-events: none;
}

.PhoneButton {
    display: flex;
    justify-content: center;
    margin-top: 15px;

}

.PhoneContainer {
    margin-left: 5px;
}

.PhoneButton input {
    height: 30px;
    width: 130px;
    background-color: #662677;
    border: none;
    border-radius: 10px;

    font-family: "Gothampro Bold";
    font-size: 14pt;
    color: #FFFFFF;
    cursor: pointer;
}

.imgM {

}

.imgD {
    display: none;
}


/*@media (min-width: 820px) {*/


/*}*/

.PhoneButton {
    justify-content: start;
}

.PhoneButton input {
    margin-left: 10px;
}


.PhoneNumbers{
    scrollbar-width: none;
}


@media (min-width: 820px) {
    .PhoneButton {
        justify-content: center;
    }

    .PhoneButton input {
        margin-left: 0px;
    }

    .Phone {
        zoom: 180%;
        -moz-transform: scale(1.5);
    }



    .imgM {
        display: none;
    }

    .imgD {
        display: block;
    }

    .PhoneImg {
        width: 340px;
        height: 442px;
    }

    .PhoneContainer {
        zoom: 90%;
        left: 65px
    }

    .whiteFogTop {

        position: absolute;
        top: 0;

        background: linear-gradient(180deg, #FFFFFF 10%, rgba(255, 255, 255, 0) 100%);
        height: 120px;
        width: 100%;

        pointer-events: none;
    }

    .whiteFogBottom {

        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, #FFFFFF 100%);
        height: 120px;
        width: 100%;

        z-index: 99;

        pointer-events: none;
    }

    @-moz-document url-prefix() {
        .PhoneContainer{
            transform: scale(0.9);
            left: 53px;
            top:0px;
        }
    }
}

@media (min-width: 1450px) {
    .Phone {
        zoom: 170%;
    }
    .PhoneButton input {
        margin-left: 10px;
    }
}