.Page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.content {
    height: 100%;
    /*margin-bottom: 30px;*/
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;

    justify-items: center;
    /*align-items: center;*/
}

.title {
    font-family: "Hauss Black";
    font-size: 18pt;
    font-weight: bold;
    color: #b29457;
    text-align: center;
    max-width: 300px;
    /*max-width: 320px;*/
    white-space: pre-wrap;
}

.title span {
    color: black;
}

.conditionsTitle {
    font-family: "Hauss Bold";
    font-size: 16pt;
    font-weight: bold;
    text-align: center;
}

.conditionText, .conditionTextD {
    font-family: "Hauss Regular";
    font-size: 10pt;
    /*font-weight: bold;*/
}

.conditionTextD{
    display: none;
}

.conditionsTable {
    margin-top: 20px;

    display: grid;
    /*grid-template-columns: 32% 65%;*/
    grid-template-columns: 100px 180px;
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 10px;

    max-height: 300px;

}

.imgs, .imgsD {
    justify-self: end;

}

.imgs img {
    /*width: 19px;*/
    height: 19px;
    margin-left: 5px;
}
.imgsD img {
    /*width: 19px;*/
    height: 19px;
    margin-left: 5px;
}

.imgsD{
    display: none;
}

.slider {
    display: grid;
    grid-template-columns: auto 165px auto;
    grid-template-rows: 49px 130px 30px;
    /*grid-template-rows: auto auto auto;*/
    grid-row-gap: 20px;

    max-width: 320px;
}

.sliderLeft {
    cursor: pointer;
    grid-row-start: 2;
    grid-column-start: 1;
}

.sliderRight {
    cursor: pointer;
    grid-row-start: 2;
    grid-column-start: 3;
}

.sliderContent {
    grid-row-start: 1;
    grid-column-start: 2;

    grid-row-end: 4;

    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
}

.louder{
    grid-row-start: 1;
    grid-column-start: 2;

    grid-row-end: 4;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    font-family: "Hauss Regular";
    font-size: 12pt;
}

.rateImg {
    width: 72px;
    height: 49px;
    background-image: url("/src/images/FivePage/layer_6.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rateTitle{
    font-family: "Hauss Medium";
    font-size: 9.13pt;
    color: white;

    text-align: start;
}

.rateName{
    font-family: "Hauss Black";
    font-size: 14.2pt;
    color: white;
}

.slide {
    display: grid;
    grid-template-rows: 49px 130px 30px;
    grid-row-gap: 17px;
    min-width: 145px;
    margin: 0 10px;
    width: 100%;

    transition: transform 0.5s;
}

.slideHeader {
    width: 100%;
    display: flex;
    height: 49px;
    justify-content: space-between;
}

.rateInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rateInfo div {
    font-family: "Alumma Regular";
    font-size: 10.14pt;
    color: #989797;

    text-align: start;
}

.phone, .phoneActive {
    cursor: pointer;
    font-family: "Hauss Book";
    font-size: 12.92pt;
    text-align: center;
    white-space: nowrap;
}

.phoneActive{
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.55);
}

.plug {
    cursor: pointer;
    /*height: 22px;*/
    background: linear-gradient(0deg, #b29457 10%, #faf6c7 40%, #b29457 100%);
    border-radius: 10px;
    font-family: "Hauss Regular";
    font-size: 12.53pt;
    font-weight: bold;
    width: 100%;
    height: 100%;

    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.55);
}

.slideFooter{
    width: 100%;
    height: 100%;
}

.brD{
    display: none;
}

@media (min-width: 820px){
    .Page{
        zoom: 70%;
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(0.7);
        }
    }

    .content{
        width: 1170px;
        margin-left: 10px;

        /*align-items: start;*/
    }

    .title{
        /*width: 100%;*/
        max-width: 100%;
        white-space: normal;

        text-align: start;
        /*font-size: 50pt;*/
        font-size: 46pt;
        /*line-height: 54pt;*/
        line-height: 54pt;

        margin-top: 30px;
        /*margin-top: 53px;*/

        width: 1100px;

        justify-self: start;

    }

    .plug{
        box-shadow: none;
        border-radius: 5px;
    }

    .conditions{
        display: flex;
        flex-direction: column;
       max-width: 1170px;
        justify-self: start;

        zoom: 80%;
    }

    .conditionsTitle{
        font-family: "Hauss Black";

        font-size: 26pt;
        font-weight: bold;
        text-transform: uppercase;
        text-align: start;
    }

    .conditionsTable{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;


        margin-top: 10px;
        margin-left: -3px;
        height: 155px;
    }

    .imgsD{
        display: block;
    }
    .conditionTextD{
        display: block;
    }

    .imgs img{
        height: auto;
        /*height: 37px;*/
        margin-right: 3px;
    }
    .imgsD img{
        height: 39px;
        margin-right: 3px;
    }

    .imgs, .imgsD{
        /*height: auto;*/
        display: flex;
        align-items: center;
        /*margin-right: 10px;*/
    }

    .conditionTextD, .conditionText{
        font-size: 16pt;
        display: flex;
        align-items: center;
        margin-right: 15px;
        color: black;
    }

    .brD{
        display: block;
        width: 50px;
    }

    .sliderContent{
        /*width: calc(165px * 5);*/
        /*width: 1170px;*/
    }

    .slider{

        height: 360px;
        justify-self: start;
        margin-left: -68px;
        grid-template-columns: auto 1170px auto;
        grid-template-rows: 80px 240px 45px;
        max-width: none;
        width: calc(1170px + 64px + 64px);
    }

    .slide{
        width: 145px;
        /*height: 360px;*/
        /*zoom: 142%;*/
        height: 0px;
        margin-right: 65px;
        margin-left: 30px;
    }

    .sliderLeft, .sliderRight{
        zoom: 142%
    }

    @-moz-document url-prefix() {

        .slide{
            /*width: 25px;*/
            height: 0px;
            margin-right: 65px;
            margin-left: 30px;
           /*-moz-transform:scale(1.42);*/

        }

        .sliderLeft, .sliderRight{
            transform: scale(1.42);
        }

        .Page{
            height: 0%;
        }

        .conditions{
            margin-top: 30px;
        }

        .slider{
            margin-top: 30px;
        }
    }
}

@media (min-width: 1450px){
    .Page{
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(1);
        }

        .Page{
            height: 99%;
        }

        .conditions{
            margin-top: 10px;
        }

        .slider{
            margin-top: 10px;
        }
    }
}
