.adminInput{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 405px;
    height: 75px;
}

.adminInput input{
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px 15px;
    font-size: 14pt;
    font-family: "Hauss Regular";
}

.adminInput textarea{
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px 15px;
    font-size: 14pt;
    font-family: "Hauss Regular";
    resize: none;
}

.saveButton, .saveResetButton, .saveTrueButton, .saveErrorButton{
    border-radius: 100%;
    border: 1px solid black;
    width: 47px;
    height: 47px;
    margin-left: 20px;
    cursor: pointer;
}

.title{
    width: 100%;
    font-family: "Hauss Regular";
    font-size: 14pt;
    min-height: 24px;
}

.controls{
    display: flex;
    /*flex-wrap: wrap;*/
    width: 100%;
}

.saveResetButton{
    border: 1px solid darkorange;
}
.saveTrueButton{
    border: 1px solid green;
}
.saveErrorButton{
    border: 1px solid red;
}


.input{
    /*box-shadow: 0px 0px 4px #0037ff;*/
    /*box-shadow: 0px 0px 4px #662677;*/
    /*box-shadow: 0px 0px 4px black;*/
    border: 1px solid black !important;
}

.inputDesktop{
    border: 1px solid #ff7100 !important;
}

.inputMobile{
    border: 1px solid #0037ff !important;
}

textarea:focus {
    z-index: 5;
    width: 500px;
    height: 200px;
    margin-bottom: 10px;
}

textarea{

}

.controls:focus-within{
    /*transition-delay: 1s;*/
    z-index: 5;
    background: white;
    padding-bottom: 10px;
    flex-wrap: wrap;
}
