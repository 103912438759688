.Page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;


}

.content {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 100%;
    position: relative;
    max-width: 380px;
    width: 100%;
}

.contentD {
    display: none;
}

.line {
    position: absolute;
    z-index: -1;
    height: 100vh;
    left: 40px;
}

.OneLine {
    background-color: #eaeaea;
    width: 73px;
    height: calc(24% - 20px);
    position: relative;
}

.OneLine::after {
    content: '';
    position: absolute; /* Абсолютное позиционирование */
    bottom: -56px;
    border: 36.6px solid transparent;
    border-top: 20px solid #eaeaea;
    z-index: -1;
}

.TwoLine {
    background-color: #eaeaea;
    width: 73px;
    height: calc(12% - 20px);
    margin-top: 92px;
    position: relative;
}

.TwoLine::after {
    content: '';
    position: absolute; /* Абсолютное позиционирование */
    bottom: -56px;
    border: 36.6px solid transparent;
    border-top: 20px solid #eaeaea;
    z-index: -1;
}

.ThreeLine {
    background-color: #eaeaea;
    width: 73px;
    position: relative;

    height: calc(17% - 50px);
    margin-top: 92px;
}

.ThreeLine::after {
    content: '';
    position: absolute; /* Абсолютное позиционирование */
    bottom: -76px;
    border: 36.6px solid transparent;
    border-top: 40px solid #eaeaea;
}

.ThreeLine img {
    position: absolute;
    bottom: -10px;
    z-index: 2;
    left: calc(calc(73px - 39px) / 2);
}

.OneLine img {
    width: 100px;
    height: 100px;
    z-index: 3;
}

.TwoLine img {
    width: 100px;
    height: 100px;
    z-index: 3;
}

.contentHeader {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: flex-end;
    margin-top: 10%;
}

.title {
    font-family: "Hauss Black";
    font-weight: bold;
    font-size: 20pt;
    max-width: 155px;
    margin-right: -26px;
    white-space: pre-wrap;
}


.item, .item2, .item3 {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin-top: 46px;
    bottom: -80px;
    left: -13px;
}

.item2 {
    bottom: -120px;
    left: -13px;
    z-index: 3;
}

.item3 {
    bottom: -135px;
    left: -13px;
    z-index: 3;
}

.itemText {
    max-width: 170px;
    margin-right: -15px;
}

.itemTextTitle {
    font-family: "Hauss Black";
    font-size: 11pt;
    color: #605e5e;
    white-space: pre-wrap;
}

.itemTextTitle span {
    color: #a2864f;
}

.itemTextP {
    margin-top: 10px;
    font-family: "Alumma Regular";
    font-weight: bold;
    font-size: 10pt;
    white-space: pre-wrap;
}


@media (min-width: 820px) {

    .Page {
        zoom: 70%;
        display: flex;
        align-items: center;
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(0.7, 0.7);
            width: 125%;
            margin-left: -80px;
        }
    }

    .content {
        display: none;
    }

    .contentD {
        display: grid;
        align-self: start;
        margin-left: 10px;
        width: calc(100% - 351px);
        height: calc(100% - var(--hederClone));
        grid-template-columns: 0% 1fr 1fr 1fr 0%;
        grid-template-rows:
                1fr
                76px
                /*640px*/
                /**/
                50px
                55px
                30px
                260px
                30px
                235px
                /**/
                1fr;

    }

    .title {
        align-self: center;
        width: 100%;
        max-width: none;
        grid-row-start: 2;
        grid-column: 2/5;
        text-transform: uppercase;
        font-size: 36pt;
    }

    .contentTitle1, .contentTitle2, .contentTitle3 {
        white-space: pre-wrap;
        font-family: "Hauss Black";
        font-size: 16pt;

    }

    .contentTitle1 span {
        color: #b4975a;
    }

    .contentTitle1 {
        grid-row-start: 4;
        grid-column-start: 2;
    }

    .contentTitle2 {
        grid-row-start: 4;
        grid-column-start: 3;
    }

    .contentTitle3 {
        grid-row-start: 4;
        grid-column-start: 4;
    }


    .contentText1, .contentText2, .contentText3 {
        white-space: pre-wrap;
        font-family: "Alumma Regular";
        font-size: 16pt;
        line-height: 20pt;
    }

    .contentText1 {
        grid-row-start: 8;
        grid-column-start: 2;
    }

    .contentText2 {
        grid-row-start: 8;
        grid-column-start: 3;
    }


    .contentText3 {
        grid-row-start: 8;
        grid-column-start: 4;
    }


    .contentBlock0 {
        grid-row-start: 6;
        grid-column-start: 1;
        position: relative;
    }

    .contentBlock1 {
        grid-row-start: 6;
        grid-column-start: 2;
        position: relative;
    }

    .contentBlock2 {
        grid-row-start: 6;
        grid-column-start: 3;
        position: relative;
    }

    .contentBlock3 {
        grid-row-start: 6;
        grid-column-start: 4;
        position: relative;
    }

    .arrow {
        position: absolute;
        left: 50px;
        top: calc(50% - calc(190px / 2));
        height: 190px;
        width: calc(100% - 100px);

        background: #eaeaea;
        z-index: 2;
    }

    .arrowOne {
        display: none;
        position: absolute;
        left: 0px;
        top: calc(50% - calc(190px / 2));
        height: 190px;
        width: calc(100% - 50px);
        background: #eaeaea;
        z-index: 2;
    }

    .arrow::after {
        content: '';
        position: absolute; /* Абсолютное позиционирование */
        border: 95px solid transparent;
        border-left: 50px solid #eaeaea;
        left: 100%;
    }

    .arrowOne::after {
        display: none;
    }

    .contentBlockImg {

        border-radius: 50%;
        border: white 6px solid;
    }


    .contentBlockImgBorder {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        padding: 8px;
        border-radius: 50%;
        background-image: linear-gradient(0deg, #b29457 0%, #faf6c7 50%, #b29457 100%);
    }

    .logoBlock {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: end;
        height: calc(100% - var(--hederClone));
        width: 15.9%;
    }

    .logoBlock img {
        height: 90%;
    }
}

@media (min-width: 1215px) {
    .contentD {
        grid-template-columns: 10% 1fr 1fr 1fr 341px;
        width: calc(100%);
        margin-left: 0;
    }
}

@media (min-width: 1450px) {
    .Page {
        zoom: 100%;
    }
    .contentD {
        grid-template-columns: 10% 1fr 1fr 1fr 341px;
        width: calc(100%);
    }
    @-moz-document url-prefix() {

        .Page {
            transform: scale(1);
            width: 100%;
        }
    }
}

@media (min-width: 1700px) {

    .Page {

        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        position: relative;
    }

    .contentD {
        grid-template-columns: 23.8% 20.1% 20.1% 20.1% 15.9%;
        width: 100%;
    }

    .arrowOne{
        display: block;
    }

    .arrowOne::after {
        display: block;
        content: '';
        position: absolute; /* Абсолютное позиционирование */
        border: 95px solid transparent;
        border-left: 50px solid #eaeaea;
        left: 100%;
    }
}