.TwoPage{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: space-between;*/
    /*position: relative;*/
}


.container{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.contentItem{

}

.img{
    display: flex;
    justify-content: center;
}

.text{
    margin-top: 20px;

    font-family: 'Alumma Regular';
    font-size: 14pt;

    text-align: center;

    max-width: 320px;

    white-space: pre-wrap;
    /*max-width: 280px;*/
}