.Page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin-bottom: 30px;
    /* margin-top: 30px; */
}


.title {
    font-family: "Hauss Black";
    font-size: 18pt;
    white-space: pre-wrap;
    text-align: center;
    /* margin-top: 50px; */
}

.numbers {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 20px;

}

.numberButtons {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(5, 20%);
    grid-template-rows: 50% 50%;
    grid-row-gap: 5px;

    justify-items: center;


}

.numberButton {
    width: 40px;
    height: 40px;
    display: grid;
    /*align-self: end;*/
}

.numberButtonTest{
    width: 40px;
    height: 40px;

    border-radius: 50%;
    border: #805913 5px solid;
    background: #2c66a0;
}

.numberButton img {
    width: 100%;
    height: 100%;
}


.numberTextBottom {
    font-family: "Alumma Regular";
    font-size: 12pt;
    font-weight: bold;
    color: #805913;
    margin-top: 20px;
    white-space: pre-wrap;

}

.rates, .ratesDesktop {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-top: 10px;

}

.setNumber, .titleDesktop, .textDesktop, .contentDesktop, .ratesDesktop{
    display: none;
}

.titleDesktop{
    font-family: "Hauss Black";
    font-weight: bold;
    font-size: 36pt;
    text-transform: uppercase;
    white-space: pre-wrap;

    margin-top: 35px;
}

.setNumber{
    display: none;
    grid-template-columns: 35% 65%;
    grid-template-rows: 1fr;
    align-items: center;
}

.setNumberInput{
    position: relative;
    width: 100%;
}
.setNumberInput input{
    top:13px;
    left: 13px;
    height: 115px;
    width: 190px;
    position: absolute;
    z-index: 2;
    /*background: red;*/
    text-align: center;

    font-family: "Hauss Black";
    font-weight: bold;
    font-size: 36pt;
    text-transform: uppercase;

    outline: none;
}

.setNumberText{
    font-family: "Alumma Regular";
    font-weight: bold;
    font-size: 20pt;
    color: #454444;
    white-space: pre-wrap;
}

.textDesktop{
    font-family: "Hauss Regular";
    font-weight: bold;
    font-size: 14pt;
    color: #454444;
}

.louderD{
    display: none;

    white-space: pre-wrap;
}
.louder{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 355px;

    font-family: "Hauss Regular";
    font-size: 12pt;

    white-space: pre-wrap;

}



@media (min-width: 820px) {

    .Page{
        zoom:70%;
        margin-top: 70px;
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(0.7);
        }
    }

    .louderD{
        display: block;

        font-family: "Hauss Regular";
        font-size: 16pt;

        text-align: center;
    }
    .louder{
        display: none;
    }

    .contentDesktop{
        display: flex;
        height: 100%;
        grid-column-start: 2;
        grid-row-start: 3;
        grid-row-end: 4;

    }

    .contentDesktop img{
        height: 100%;
    }

    .content{
        grid-column-start: 3;
        grid-row-start: 3;

        width: 100%;

        display: grid;
        grid-template-columns:1fr ;
        grid-template-rows: 160px 160px 35px 1fr;
        /*grid-template-rows: 160px 160px 35px 1fr;*/
    }

    .ratesDesktop{
        align-self: start;
        margin-top: 0;
        display: grid;
        width: 100%;
        height: 390px;
        /*zoom:170%*/
    }


    .rates{
        display: none;
    }

    .Page{
        display: grid;
        grid-template-columns: 1fr 400px 700px 1fr ;
        grid-template-rows: var(--hederClone) 1fr 760px 1fr;
    }


    .title, .numbers{
        display: none;
    }

    .setNumber{
        display: grid;
    }

    .titleDesktop{
        display: flex;

    }

    .textDesktop{
        display: flex;
        justify-content: end;
    }

}

@media (min-width: 1180px) {

}

@media (min-width: 1450px) {
    .Page{
        zoom:100%;
        margin-top: 0;
    }

    @-moz-document url-prefix() {

        .Page {
            transform: scale(1);
        }
    }
}




