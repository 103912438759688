.TwelvePage{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
}

.title {
    font-family: "Hauss Black";
    font-size: 18pt;
}

.Map, .MapD{
    width: 100%;
    min-height: 178px;
}

.MapD{
    display: none;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.blockText, .blockTextM, .blockText1, .blockText2, .blockText3, .email{
    font-family: "Alumma Regular";
    font-size: 11.5pt;
    font-weight: bold;
    text-align: center;
    white-space: pre-wrap;

    max-width: 210px;
}
  .blockText1, .blockText2, .blockText3{
    font-style: italic;
}

.email{
    color: #3a91ce;
    text-decoration-line: underline;
}

.links{
    min-width: 85px;
    display: flex;
    justify-content: space-between;
}

.blockInfo, .img1, .icons{
    display: none;
}

.links a img{
    height: 34px;
}

@media (min-width: 820px) {


    .TwelvePage{
        zoom: 70%;
    }

    @-moz-document url-prefix() {

        .TwelvePage {
            transform: scale(0.7);
        }
    }

    .blockTextM, .links, .email{
        display: none;
    }

    .content{
        margin-top: 90px;
        width: 100%;
        height: calc(100% - var(--hederClone));

        display: grid;

        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 75px 60px 235px 35px 35px 35px;

        grid-row-gap: 30px;
        grid-column-gap: 45px;
    }

    .MapD{
        display: block;
        min-height: 234px;
        min-width: 355px;
        align-self: end;
        margin-bottom: -4px;
    }

    .Map{
        display: none;
    }

    .title{
        grid-column: 2/5;
        grid-row-start: 1;

        font-size: 34pt;
    }

    .blockInfo{
        grid-column: 2/4;
        grid-row-start: 2;

        display: flex;

    }

    .img1{
        display: block;
        grid-column-start: 2;
        grid-row-start: 3;
        /*height: 234px;*/
        /*height: 100%;*/
        /*align-self: end;*/
        /*width: 353px;*/
    }
    .MapD{
        grid-column-start: 3;
        grid-row-start: 3;
    }

    .icons{
        display: block;
        min-width: 200px;
        align-self: end;
        grid-column-start: 4;
        grid-row-start: 3;
    }

    .blockText1{
        grid-column: 2/5;
        grid-row-start: 4;
        /* font-style: italic; */
    }
    .blockText2{
        grid-column: 2/5;
        grid-row-start: 5;
        /* font-style: italic; */
    }
    .blockText3{
        grid-column: 2/5;
        grid-row-start: 6;
        /* font-style: italic; */
    }

    .blockText1,.blockText2,.blockText3{
        max-width: none;
        width: 100%;
        white-space: normal;
        text-align: start;
        font-style: italic;
        font-family: "Alumma Regular";
        font-weight: bold;
        font-size: 16pt;
    }

    .blockInfo{
        font-size: 18pt;
        font-family: "Alumma Regular";
        font-weight: bold;
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
    }

    .lineInfo{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .lineInfoRight{
        color: #3a91ce;
        text-decoration-line: underline;
        font-size: 16pt;
        font-style: normal;
    }

    .lineInfoRight1{
        color: #3a91ce;
        font-size: 16pt;
        font-style: normal;
    }

}

@media (min-width: 1450px) {
    .TwelvePage{
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .TwelvePage {
            transform: scale(1);
        }
    }
    .content{
        margin-top: 180px;
    }
}