.Menu{
    min-height: 380px;
    min-width: 300px;
    background-color: rgba(129, 129, 129, 0.9);
    top:105px;
    position: absolute;
    margin-left: calc(-300px + 20px);
    zoom: 122.5%;
    display: flex;
    justify-content: center;
    touch-action: none;

}

.Menu::before {
    content: '';
    position: absolute; /* Абсолютное позиционирование */
    right: 0px;
    top: -39.45px;
    border: 20px solid transparent;
    border-bottom: 20px solid rgba(129, 129, 129, 0.9);
}


.container{
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;
}

.item{
    font-family: "Alumma Regular";
    font-size: 18pt;
    color: white;
    text-align: center;
    white-space: pre-wrap
}