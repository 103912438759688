.NinePage {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    /*align-items: center;*/

    position: relative;

}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    /*margin-bottom: 30px;*/
}

.title {
    font-family: "Hauss Black";
    font-size: 18pt;

    white-space: pre-wrap;
}

.imageTextContent, .blockText {
    font-family: "Alumma Regular";
    font-size: 11.5pt;
    font-weight: bold;
    text-align: center;

    white-space: pre-wrap;

}

.imageTextContent {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 26px 35px ;
    width: 268px;
    /*width: 320px;*/

}

.blockText{
    width: 320px;
    font-size: 11.5pt;
}

.imageText img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 100%;*/

}

.imageText {
    /*min-width: ;*/
    width: 100%;
    height: 248px;
    min-width: 386px;
    background-size: cover;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.goldLogo, .img1, .img2, .img3, .imgs{
    display: none;
}


@media (min-width: 820px){
    .goldLogo, .img1, .img2, .img3{
        display: block;
    }

    .goldLogo{
        grid-row-start: 2;
        grid-column-start: 2;
    }

    .imageText{
        grid-row-start: 2;
        grid-column-start: 3;
    }

    .imgs{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        grid-row-start: 3;
        grid-column: 2/4;

    }
    .blockText{
        width: 100%;
        grid-row-start: 4;
        grid-column: 2/4;

        font-size: 17pt;
        text-align: start;
        line-height: 21pt;
    }

    .logo{
        display: none;
    }

    .imageText{
        background-image: none !important;
        width: 100%;
        height: 100%;
    }

    .imageTextContent{
        background-color: rgba(255, 255, 255, 0);
        padding: 0 ;
        width: auto;

        font-size: 17pt;
        text-align: start;
        line-height: 21pt;
    }

    .NinePage {
        zoom: 70%;

        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        /*align-items: center;*/

        position: relative;

    }



    .content {
        margin-top: 90px;
        width: 100%;
        height: calc(100% - var(--hederClone));

        display: grid;
        grid-template-columns: 1fr 170px 1000px 1fr;
        grid-template-rows: 76px 190px 220px 130px;
        grid-row-gap: 30px;
    }

    @-moz-document url-prefix() {

         .NinePage{
             transform: scale(0.7);
             margin-top: 0px;
             height: 25%;
         }
     }

    .title {
        grid-row-start: 1;
        grid-column: 2/4;
        font-family: "Hauss Black";
        font-weight: bold;
        font-size: 36pt;
    }
}

@media (min-width: 1450px){
    .NinePage{
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .NinePage{
            transform: scale(1);
            height: 100%;
        }
    }
}