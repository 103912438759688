.WhyUsPage{
    width: 100%;
    height: 100%;
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    position: relative;

}


.oneBlockText{

}
.textMobile{
    /*margin-top: 155px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    zoom:70%

}

.oneBlockText{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textTop{
    font-family: "Hauss Black";
    font-size: 25pt;
}

.textBottom{
    font-family: "Hauss Black";
    font-size: 39pt;
    color: #a28e59;
}

.twoBlockText{
    /*margin-top: 30px;*/
    font-family: "ALSAlumma";
    font-size: 18pt;
    text-align: center;
    white-space: pre-wrap;
}

.DesktopContainer{
    display: none;
}

@media (min-width: 820px){

    .textMobile, .twoBlockText{
        display: none;
    }



    .WhyUsPage{
        display: grid;

        grid-template-columns: 1fr 665px 200px 300px 1fr;
        grid-template-rows: var(--hederClone) 1fr;

        zoom: 75%;
    }

    .DesktopContainer{
        grid-column-start: 2;
        grid-row-start: 2;
        display: grid;
        grid-template-rows: 1fr 600px 185px;
    }

    .DesktopContent{
        grid-row-start: 2;

        display: grid;

        grid-template-rows: 228px 1fr 93px 1fr 240px;
    }


    .Phone{
        grid-column-start: 4;
        grid-row-start: 2;
        display: flex;
        align-self: end;
        justify-self: end;
        margin-right: -210px;
    }

    @-moz-document url-prefix() {

        .WhyUsPage{
            transform: scale(0.7);
        }

        .DesktopContainer{
            margin-top: 115px;
        }
        .Phone {
           grid-row-start: 2;
            transform: scale(1);
            transform-origin: left top;
            margin-right: 0px;
            margin-bottom: 195px;
        }
    }

    .desktopTitle{
        grid-row-start: 1;

        background: linear-gradient(45deg, #A08C57 24.61%, #F0E7AB 46.85%, #F0E7AB 54.66%, #F0E7AB 61.46%, #A08C57 77.55%);

        font-family: "Alumma Regular";
        font-size: 44pt;
        font-weight: bold;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .desktopTitle span{
        font-family: "Hauss Black";
        font-size: 66pt;
        font-weight: bold;
        color: white;

        text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.5);
    }

    .desktopTitle2{
        grid-row-start: 3;

        font-family: "Alumma Regular";
        font-size: 34pt;
        font-weight: bold;
        color:#6b6b6b;

        white-space: pre-wrap;
        text-align: start;
    }

    .desktopTextList{
        grid-row-start: 5;
        font-family: "Alumma Regular";
        font-size: 18pt;
        line-height: 20pt;
        font-weight: bold;
        color:black;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        text-indent: -18px;
        margin-left: 70px;

        white-space: pre-wrap;

    }

    .desktopTextList span{
        text-decoration-line: underline;
        cursor: pointer;
    }

}

@media (min-width: 1180px){
    .WhyUsPage{
        /*zoom: 100%;*/
    }


}

@media (min-width: 1450px){
    .WhyUsPage{
        zoom: 100%;
    }

    @-moz-document url-prefix() {

        .WhyUsPage {
            transform: scale(1);
        }
    }
}