.title{
    width: 100%;
    font-family: "Hauss Regular";
    font-size: 14pt;
    min-height: 24px;
}

.ChangeReviewModule{
    width: 92%;
    margin-top: 40px;
}

.ChangeReviewModuleDiv{
    width: 100%;
    /*height: 25px;*/
    border-radius: 10px;
    border: red 1px solid;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
}

.slider{
    display: flex;
}

.sliderContent{
    display: flex;
    gap: 20px;
    max-width: 790px;
    min-width: 790px;
    width: 790px;
    overflow: hidden;
    position: relative;
}

.sliderB{
    cursor: pointer;
}