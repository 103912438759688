.FourPage {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.fonTop {
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 82%;
    background: radial-gradient(45.7% 45.7% at 50% 50%, rgba(0, 0, 0, 0.77) 0%, #000000 100%);
}

.fonBottom {
    top: 82%;
    left: 0;

    position: absolute;
    z-index: -1;
    background: radial-gradient(45.7% 45.7% at 50% 50%, rgba(0, 0, 0, 0.77) 0%, #000000 100%);
    width: 100%;
    height: 18%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin-bottom: 30px;
}

.contentItem {
    display: flex;
    max-width: 300px;
}

.text {
    margin-left: 10px;

    font-family: "Alumma Regular";
    font-size: 16pt;
    color: white;

    display: flex;
    align-items: center;

    white-space: pre-wrap;
}

.title {
    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(75deg, #b29457 30%, #faf6c7 50%, #b29457 70%);

    font-family: "Hauss Black";
    font-size: 18pt;
    max-width: 210px;
    margin-top: 30px;

    text-align: center;

    white-space: pre-wrap;
}

.img {
    width: 66px;
    height: 66px;

}

.img img {
    /*width: 100%;*/
    height: 100%;
}

@media (min-width: 820px) {

    .FourPage {
        display: grid;
        grid-template-columns: 1fr 820px 1fr;
        grid-template-rows: var(--hederClone) 1fr 60px;
        /*align-items: center;*/
    }

    .content {

        zoom: 70%;
        -moz-transform: scale(0.8);

        /*align-self: end;*/
        grid-row-start: 2;
        grid-column-start: 2;

        display: grid;


        grid-template-columns: 1fr 1fr 1fr;
        /*grid-template-rows: 240px 255px 255px;*/
        grid-template-rows: 1fr 1fr 1fr;

        /*grid-row-gap: 20px;*/

        align-items: center;

    }

    @-moz-document url-prefix() {

        .content {
            transform: scale(0.7);
        }
    }

    .title {
        grid-column: 1/4;
        justify-self: center;
        max-width: 400px;
        font-size: 44pt;
    }

    .contentItem {
        /*grid-area: item;*/

        justify-self: center;
        align-self: center;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .img {
        display: flex;
        justify-content: center;
        width: 153px;
        height: 153px;
    }

    .img img {
        width: 100%;
    }

    .text {
        margin-top: 20px;
        text-align: center;
    }

    .fonTop {
        top: 0;
        height: 80%;
    }

    .fonBottom {
        top: 80%;
        height: 20%;
    }

}

@media (min-width: 1450px) {

    .FourPage {
        display: grid;
        grid-template-columns: 1fr 1170px 1fr;
        grid-template-rows: 160px 1fr 60px;
    }

    .content {
        zoom:100%;

        grid-row-start: 2;
        grid-column-start: 2;

        display: grid;


        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 240px 255px 255px;

        grid-row-gap: 20px;
    }

    @-moz-document url-prefix() {

        .content {
            transform: scale(1);
        }
    }


}